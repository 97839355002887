/*========================================
=        SYSTEM HELPER FUNCTIONS         =
========================================*/

function runOnMobile (functionName, args){
  try {
    if(args === undefined){
      Android[functionName]();
    } else {
      Android[functionName](JSON.stringify(args));
    }
  } catch(e){/* Failed because we are not using an Android device or no such method declared in mobile bridge */}
  try {
    if(args === undefined){
      webkit.messageHandlers[functionName].postMessage("{}");
    } else {
      webkit.messageHandlers[functionName].postMessage(JSON.stringify(args));
    }
  } catch(e){/* Failed because we are not using an iOS device or no such method declared in mobile bridge */}
};



/**
 * request the mobile application to fetch the latest FCM token and send it
 * back to the web-client
 */
function requestPushTokenFromApp(){
  runOnMobile("requestPushToken");
};


$(document).ready(function () {
  // Get Firebase Token and check if User Loged in
  window.addEventListener("onPushTokenReceived", function(event){
    var auth_token = Cookies.get('token');
    var old_fcm_token = Cookies.get('FCMT');
    var response = typeof(event.detail) === "string" ? JSON.parse(event.detail) : event.detail;
    var fcm_token = response.token;
    var type = response.type;
    var api_base_url = 'https://api.get-in.com'; // TODO - Set API
    var fireBasePublicKey = '658400122319';
    console.log("getin.js auth_token", auth_token, "getin.js fcm_token", fcm_token, 'response', response, 'event', event);
    if(auth_token && fcm_token){
      $.ajax({
        type: "POST",
        url: api_base_url + '/api/user-notification/firebase/' + type + '/save',
        headers: {
          'Authorization': 'Bearer ' + auth_token
        },
        data: {'token': fcm_token, 'public_key': fireBasePublicKey, 'old_token': old_fcm_token},
        dataType: "json",
        success: function(response) {
          Cookies.set('FCMT', fcm_token, {expires: 7, path:'/', secure: ((location.protocol == 'https:') ? true : false)});
        },
        error: function (jqXHR) {
          console.log("getin.js AJAX ERROR: ", jqXHR.status)
        }
      });
    }
  }, false);

})
